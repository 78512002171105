<template>
    <div class='contentData'>
        <el-form :model="data" :rules="rules" ref="pestDataShare" label-width="140px" class="demo-data">
            <div class='flex dataCommon'>
                <el-form-item label="服务机构照片" prop="companyPic">
                   <uploadImg @changeImg='changeImg'></uploadImg>
                </el-form-item>
                <el-form-item label="服务机构类型" prop="serveType">
                     <el-select v-model="data.serveType" placeholder="请选择">
                        <el-option
                        v-for="item in serviceTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            
            <div class='flex dataCommon'>
                <el-form-item label="服务机构名称" prop="companyName">
                    <el-input v-model="data.companyName" maxlength='30'></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="serveName">
                    <el-input v-model="data.serveName" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="联系邮箱" prop="serveEmail">
                    <el-input v-model="data.serveEmail" maxlength='30'></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="servePhone">
                   <el-input v-model="data.servePhone" maxlength='11'></el-input>
                </el-form-item>
            </div>
            <el-form-item label="地址" prop="serveProvince">
                    <addressSelect @addressInfo='addressInfo' :address='labAddress'></addressSelect>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="详细地址" prop="serveAddress">
                    <el-input v-model="data.serveAddress"  maxlength="30"></el-input>
                </el-form-item>
            </div>
            <el-form-item label="服务详情" prop="serveDetail">
                <el-input v-model="data.serveDetail" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none'></el-input>
            </el-form-item>
             <el-form-item label="机构介绍" prop="serveContent">
                <el-input v-model="data.serveContent"  maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none'></el-input>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="价格面议" prop="promoteItem">
                    <el-radio-group v-model="price" @change="changePrice">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div>
                <el-form-item label="价格区间" prop="promoteItem" v-if='priceCheck'>
                    <el-input v-model="priceRange.min" placeholder="最低价格"  type="number" class='price'></el-input>
                    <el-input v-model="priceRange.max"  placeholder="最高价格" type="number"></el-input>
                </el-form-item>

            </div>
            <div class='flex dataCommon'>
                <el-form-item label="上传附件" prop="moduleList">
                    <uploadMutImg :moduleList='moduleList'  @changeImgList='changeImgList'></uploadMutImg>
                </el-form-item>
            </div>
            <integral v-bind:checked = 'checked' v-on:changeSelectIndex='changeSelectIndex($event)' v-bind:selectIndex = 'selectIndex' v-on:changeRules='changeRules($event)'></integral>
            <div class='submit'>
              <el-button @click='onSaveInfo("pestDataShare")'>保存</el-button>
              <el-button class='submitData' @click='onSubmitInfo("pestDataShare")'>提交</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
import Vue from 'vue';
import { Radio, RadioGroup, Upload, Loading, Message } from 'element-ui';
import uploadImg from '@/component/uploadImg/index.vue';
import integral from './integral';
import uploadMutImg from '@/component/uploadMutImg/index.vue';
import addressSelect from '@/component/addressSelect/index.vue';
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Upload);
Vue.use(Loading);

  export default {
    components: {
        uploadImg,
        integral,
        uploadMutImg,
        addressSelect
    },
    data() {
        const validateShowImg = (rule, value, callback) => {
            if (!this.data.companyPic) {
                callback(new Error('请上传头像'));
            }
            callback();
        };
        const validatePhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入联系电话'));
            } else {
                const reg = /^1[3456789]\d{9}$/;
                if (!reg.test(this.data.servePhone)) {
                    callback(new Error('请输入正确的手机号码'));
                }
                callback();
            }
        };
        const validateAddress =  (rule, value, callback) => {
            if (!this.data.serveProvince) {
                 callback(new Error('请选择地址'));
            }
            callback();
        };
        const validateEmail = (rule, value, callback) => {
            const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
            if (!reg.test(value)){
                callback(new Error('邮箱格式不正确'));
            }
            callback();
        };
        

        return {
            rules: {
                companyPic: [
                     { required: true, validator: validateShowImg  }
                ],
                serveType: [
                    { required: true, message: '请选择服务机构类型', trigger: 'blur'  }
                ],
                companyName: [
                    { required: true, message: '请输入服务机构名称', trigger: 'blur'  },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                serveDetail: [
                     { required: true, message: '请输入服务详情', trigger: 'blur' }
                ],
                serveContent: [
                    { required: true, message: '请输入机构介绍', trigger: 'blur' }
                ],
                serveName: [
                    { required: true, message: '请输入联系人名称', trigger: 'blur' }
                ],
                servePhone: [
                    { required: true, validator: validatePhone, trigger: 'blur' }
                ],
                serveEmail: [
                    { required: true,   validator: validateEmail, trigger: 'blur' }
                ],
                serveProvince: [
                     { required: true, validator: validateAddress }
                ],
                serveAddress: [
                     { required: true, message: '请输入详细地址', trigger: 'blur' }
                ]
            },
            data: {
                companyPic: '', // 头像
                serveType: '', // 服务机构
                companyName: '', // 服务机构名称
                serveDetail: '', // 服务详情
                serveContent: '', // 机构介绍
                servePhone: '', // 联系人电话
                servePrice: '', // 服务价格
                imgPaths: '',
                viewCount: 0,
                serveName: '', // 名称
                serveEmail: '', // 邮箱
                serveProvince: '',
                serveCity: '',
                serveArea: '',
                serveAddress: ''
            },
            param: {
                content: '',
                integral: 0,
                status: 0,
                type: 6,
                isPublic: 0
            },
            checked: true,
            selectIndex: -1,
            timeOut: new Date(),
            imgList: [],
            moduleList: {
                module: 'dataShare',
                subModule: 'serviceShare'
            },
            serviceTypeList: [{
                name: '法律服务',
                id: 1
            }, {
                name: '管理咨询',
                id: 2
            }, {
                name: '金融服务',
                id: 3
            }, {
                name: '创业服务',
                id: 4
            }, {
                name: '科技创新',
                id: 5
            }],
            priceCheck: true,
            price: 1,
            priceRange: {
                min: '',
                max: ''
            },
            labAddress: { 
                disabled: false, // 只为了显示不能选择地址
                provinceId: '',
                cityId: '',
                districtId: ''
            }
        };
    },
    async mounted() {
    },
    methods: {
        changeImg(file){
            this.data.companyPic = file.filePath;
        },
        // 修改查看规则
        changeRules(data) {
            this.param.integral = data.integral;
            this.param.isPublic = data.isPublic;
            if (data.isPublic === 0) {
            this.checked = false;
            } else {
            this.checked = true;
            }
        },
        changeSelectIndex(data) {
            this.selectIndex = Number(data);
        },
        // 保存
        onSaveInfo(formName) {
            this.operation(formName, 0);
        },
        // 提交
        onSubmitInfo(formName) {
            this.operation(formName, 1);
        },
        operation(formName, idx) {
            if (new Date() - this.timeOut < 1000) {
                return true;
            }
            this.timeOut = new Date();
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.param.isPublic === 0 && this.param.integral === 0) {
                        Message('请设置规则积分');
                        return false;
                    }
                    this.param.status = idx;
                    this.addRecord();
                } else {
                    window.scroll(0, 400);
                    return false;
                }
            });
        },
        // 保存和提交记录
        async addRecord() {
            const loadingInstance = Loading.service({
                fullscreen: true,
                background: 'rgba(0,0,0,.4)'
            });
            const obj = this.param;
            this.data.imgPaths = this.pictureCollation();
            if (this.priceCheck) {
                if (!this.priceRange.min) {
                    this.data.servePrice = '';
                } else if (this.priceRange.min > this.priceRange.max) {
                    this.data.servePrice = '';
                    Message('最低价格不能超过最高价格呦！');
                    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close();
                    });
                    return false;
                } else {
                    const servePrice = this.priceRange.min + ',' + this.priceRange.max;
                    this.data.servePrice = servePrice;
                }
            } else {
                this.data.servePrice = '';
            }
            obj.content = JSON.stringify(this.data);
            let api = '';
            if (this.$route.query && this.$route.query.update) {
                api = this.$fetchApi.updateRecord.api;
            } else {
                api = this.$fetchApi.addMechanismRecord.api;
            }
            const data = await this.$fetchData.fetchPost(obj, api, 'json');
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close();
            });
            if (data.code === '200') {
                if (data.result === 1) {
                    this.isSubmit = false;
                    Message('提交成功，请至app查看');
                }
            }
        },
        // 附件图片的整理
        pictureCollation() {
            let imgPaths = '';
            if (this.imgList.length > 0) {
                this.imgList.forEach((item, index) => {
                imgPaths += item;
                if (index < this.imgList.length - 1) {
                    imgPaths += ',';
                }
                });
            }
            return imgPaths;
        },
        changeImgList(fileList) {
            const files = [];
            if (fileList.length > 0) {
               fileList.forEach(item => {
                   files.push(item.filePath);
               });
            }
            this.imgList = files;
        },
        /** 返回三级地址信息 */
        addressInfo(val) {
            this.data.serveProvince = val[0].id; // 居住地址 省id
            this.data.serveCity = val[1].id;         // 居住地址 市id
            this.data.serveArea = val[2].id; // 居住地址 区id
        },
        // 价格是否面议
        changePrice(data) {
            if (data === 2) {
                this.priceCheck = false;
            } else {
                this.priceCheck = true;
            }
        }
    }
  };
</script>
<style lang="less" scoped>
    @import './common.less';
    .price{
        margin-right:20px;
    }
</style>