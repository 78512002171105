<template>
    <div class='contentData'>
        <el-form :model="data" :rules="rules" ref="instrumentShare" label-width="140px" class="demo-data">
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>仪器数据信息</span></div>
            <div class='flex dataCommon'>
                <el-form-item label="仪器照片" prop="photo">
                   <uploadImg @changeImg='changeImg'></uploadImg>
                </el-form-item>
                <el-form-item label="仪器名称" prop="deviceName">
                    <el-input v-model="data.deviceName" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <el-form-item label="仪器产地" prop="deviceProductProvince">
                <addressSelect @addressInfo='placeInfo' :address='productAddress'></addressSelect>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="详细地址" prop="deviceAddress">
                    <el-input v-model="data.deviceAddress" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <el-form-item label="仪器地址" prop="deviceApplyProvince">
                <addressSelect @addressInfo='addressInfo' :address='labAddress'></addressSelect>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="详细地址" prop="deviceApplyAddress">
                    <el-input v-model="data.deviceApplyAddress" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="联系方式" prop="devicePhone">
                    <el-input v-model="data.devicePhone" type="tel" maxlength='11'></el-input>
                </el-form-item>
                <el-form-item label="产品类型" prop="deviceProductType">
                    <el-select v-model="data.deviceProductType" placeholder="请选择">
                          <el-option
                            v-for="item in equipmentTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                          </el-option>
                        </el-select>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="规格型号" prop="deviceProductModel">
                    <el-input v-model="data.deviceProductModel" maxlength='30'></el-input>
                </el-form-item>
                <el-form-item label="制造商" prop="deviceManufacturer">
                    <el-input v-model="data.deviceManufacturer" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <el-form-item label="产品说明" prop="deviceProductIntroduction">
                <el-input v-model="data.deviceProductIntroduction" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none'></el-input>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="上传附件" prop="moduleList">
                    <uploadMutImg :moduleList='moduleList'  @changeImgList='changeImgList'></uploadMutImg>
                </el-form-item>
            </div>
            <integral v-bind:checked = 'checked' v-on:changeSelectIndex='changeSelectIndex($event)' v-bind:selectIndex = 'selectIndex' v-on:changeRules='changeRules($event)'></integral>
            <div class='submit'>
              <el-button @click='onSaveInfo("instrumentShare")'>保存</el-button>
              <el-button class='submitData' @click='onSubmitInfo("instrumentShare")'>提交</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
import Vue from 'vue';
import { Radio, RadioGroup, Upload, Loading, Message } from 'element-ui';
import uploadImg from '@/component/uploadImg/index.vue';
import addressSelect from '@/component/addressSelect/index.vue';
import integral from './integral';
import uploadMutImg from '@/component/uploadMutImg/index.vue';
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Upload);
Vue.use(Loading);
  export default {
    components: {
        uploadImg,
        integral,
        addressSelect,
        uploadMutImg
    },
    data() {
        const validatePhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入联系电话'));
            } else {
                const reg = /^1[3456789]\d{9}$/;
                if (!reg.test(this.data.devicePhone)) {
                    callback(new Error('请输入正确的手机号码'));
                }
                callback();
            }
        };
        const validateDeviceProductProvince = (rule, value, callback) => {
            if (this.data.deviceProductProvince === '') {
                callback(new Error('请选择仪器产地地址'));
            }
            callback();
        };
        const validateDeviceApplyProvince = (rule, value, callback) => {
            if (this.data.deviceApplyProvince === '') {
                callback(new Error('请选择仪器地址'));
            }
            callback();
        };
        const validateDevicePic = (rules, value, callback) => {
            if (this.data.devicePic) {
                callback(new Error('请上传头像'));
            }
            callback();
        };
      
        return {
            rules: {
                devicePic: [
                    { required: true, validator: validateDevicePic }
                ],
                deviceName: [
                    { required: true, message: '请输入专家名称', trigger: 'blur' },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                deviceProductProvince: [
                    { required: true, validator: validateDeviceProductProvince }
                ],
                deviceApplyProvince: [
                    { required: true, validator: validateDeviceApplyProvince }
                ],
                deviceAddress: [
                     { required: true, message: '请输入仪器产地的详细地址', trigger: 'blur' },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                deviceApplyAddress: [
                     { required: true, message: '请输入仪器地址的详细地址', trigger: 'blur' },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                devicePhone: [
                    { required: true, validator: validatePhone, trigger: 'blur' }
                ],
                deviceProductType: [
                    { required: true, message: '请选择产品类型', trigger: 'blur' }
                ],
                deviceProductModel: [
                    { required: true, message: '请输入规格型号', trigger: 'blur' },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                deviceProductIntroduction: [
                    { required: true, message: '请输入产品说明', trigger: 'blur' }
                ]
            },
            data: {
                deviceName: '', // 设备名字
                devicePic: '', // 设备图片
                devicePhone: '', // 联系方式
                deviceProductType: '', // 产品类型
                deviceProductModel: '', // 规格型号
                deviceManufacturer: '', // 制造商
                deviceProductIntroduction: '', // 产品说明
                deviceMachineTool: '', // 机局大类
                deviceServiceInfo: '', // 服务信息
                cityType: '1',
                deviceApplyProvince: '',
                deviceApplyCity: '',
                deviceApplyArea: '',
                deviceApplyAddress: '',
                deviceAddress: '', // 地址
                deviceProductProvince: '',
                deviceProductCity: '',
                deviceProductArea: '',
                imgPaths: ''
            },
            param: {
                content: '',
                integral: 0,
                status: 0,
                type: 5,
                isPublic: 0
            },
            checked: true,
            selectIndex: -1,
            equipmentTypeList: [],
            timeOut: new Date(),
            moduleList: {
                module: 'dataShare',
                subModule: 'instrumentShare'
            },
            imgList: [],
            labAddress: { 
                disabled: false, // 只为了显示不能选择地址
                provinceId: '',
                cityId: '',
                districtId: ''
            },
            productAddress: {
                disabled: false, // 只为了显示不能选择地址
                provinceId: '',
                cityId: '',
                districtId: ''
            }
        };
    },
    mounted() {
        this.getEquipmentTypeList();
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                alert('submit!');
            } else {
                // console.log('error submit!!');
                return false;
            }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        changeImg(file){
            this.data.devicePic = file.filePath;
        },
        // 修改查看规则
        changeRules(data) {
            this.param.integral = data.integral;
            this.param.isPublic = data.isPublic;
            if (data.isPublic === 0) {
            this.checked = false;
            } else {
            this.checked = true;
            }
        },
        changeSelectIndex(data) {
            this.selectIndex = Number(data);
        },
        /** 返回三级地址信息 */
        addressInfo(val) {
            this.data.deviceApplyProvince = val[0].id; // 居住地址 省id
            this.data.deviceApplyCity = val[1].id;         // 居住地址 市id
            this.data.deviceApplyArea = val[2].id; // 居住地址 区id
        },
        /** 返回三级地址信息 */
        placeInfo(val) {
            this.data.deviceProductProvince = val[0].id; // 居住地址 省id
            this.data.deviceProductCity = val[1].id;         // 居住地址 市id
            this.data.deviceProductArea = val[2].id; // 居住地址 区id
        },
        // 产品类别
        async getEquipmentTypeList() {
            const api = this.$fetchApi.equipmentTypeList.api;
            const data = await this.$fetchData.fetchPost({}, api, 'json');
            if (data.code === '200') {
                this.equipmentTypeList = data.result;
            }
        },
        // 保存
        onSaveInfo(formName) {
            this.operation(formName, 0);
        },
        // 提交
        onSubmitInfo(formName) {
            this.operation(formName, 1);
        },
        operation(formName, idx) {
            if (new Date() - this.timeOut < 1000) {
                return true;
            }
            this.timeOut = new Date();
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.param.isPublic === 0 && this.param.integral === 0) {
                        Message('请设置规则积分');
                        return false;
                    }
                    this.param.status = idx;
                    this.addRecord();
                } else {
                    window.scroll(0, 400);
                    return false;
                }
            });
        },
        // 保存和提交记录
        async addRecord() {
            const loadingInstance = Loading.service({
                fullscreen: true,
                background: 'rgba(0,0,0,.4)'
            });
            const obj = this.param;
            this.data.imgPaths = this.pictureCollation();
            obj.content = JSON.stringify(this.data);
            let api = '';
            if (this.$route.query && this.$route.query.update) {
                api = this.$fetchApi.updateRecord.api;
            } else {
                api = this.$fetchApi.addInstrumentRecord.api;
            }
            const data = await this.$fetchData.fetchPost(obj, api, 'json');
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close();
            });
            if (data.code === '200') {
                if (data.result === 1) {
                    this.isSubmit = false;
                    Message('提交成功，请至app查看');
                }
            }
        },
        // 附件图片的整理
        pictureCollation() {
            let imgPaths = '';
            if (this.imgList.length > 0) {
                this.imgList.forEach((item, index) => {
                imgPaths += item;
                if (index < this.imgList.length - 1) {
                    imgPaths += ',';
                }
                });
            }
            return imgPaths;
        },
        changeImgList(fileList) {
            const files = [];
            if (fileList.length > 0) {
               fileList.forEach(item => {
                   files.push(item.filePath);
               });
            }
            this.imgList = files;
        }
    }
  };
</script>
<style lang="less" scoped>
    @import './common.less';
</style>