<template>
    <div class='contentData'>
        <el-form :model="data" :rules="rules" ref="pestDataShare" label-width="140px" class="demo-data">
            <!-- <div class='title clearfix'><span class='first fl'></span><span class='second fl'>病虫害数据信息</span></div> -->
            <div class='flex dataCommon'>
                <el-form-item label="病虫害图片" prop="showImg">
                   <uploadImg @changeImg='changeImg'></uploadImg>
                </el-form-item>
                <el-form-item label="病虫害类型" prop="bigClassificationId">
                    <el-select v-model="data.bigClassificationId" @change="changeBigClassificationId" placeholder="请选择">
                        <el-option
                        v-for="item in diseasesTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <el-form-item label="虫害/病害类别" prop="midClassificationId">
                <el-select v-model="data.midClassificationId" @change="changeMidClassificationId" placeholder="请选择">
                    <el-option
                    v-for="item in speciesTypeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="详细类别" prop="smallClassificationId">
                    <el-select v-model="data.smallClassificationId" placeholder="请选择">
                        <el-option
                        v-for="item in detailTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <el-form-item label="名称" prop="deviceApplyProvince">
                <el-input v-model="data.name" maxlength='30'></el-input>
            </el-form-item>
            <el-form-item label="习性特点" prop="behaviorCharacteristics">
                <el-input v-model="data.behaviorCharacteristics" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none'></el-input>
            </el-form-item>
            <el-form-item label="危害状态及特点" prop="symptom">
                <el-input v-model="data.symptom" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none'></el-input>
            </el-form-item>
            <el-form-item label="发生规律" prop="happenRegularity">
                <el-input v-model="data.happenRegularity" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none'></el-input>
            </el-form-item>
            <el-form-item label="防治方案" prop="controlMethod">
                <el-input v-model="data.controlMethod" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none'></el-input>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="上传附件" prop="moduleList">
                    <uploadMutImg :moduleList='moduleList'  @changeImgList='changeImgList'></uploadMutImg>
                </el-form-item>
            </div>
            <integral v-bind:checked = 'checked' v-on:changeSelectIndex='changeSelectIndex($event)' v-bind:selectIndex = 'selectIndex' v-on:changeRules='changeRules($event)'></integral>
            <div class='submit'>
              <el-button @click='onSaveInfo("pestDataShare")'>保存</el-button>
              <el-button class='submitData' @click='onSubmitInfo("pestDataShare")'>提交</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
import Vue from 'vue';
import { Radio, RadioGroup, Upload, Loading, Message } from 'element-ui';
import uploadImg from '@/component/uploadImg/index.vue';
import integral from './integral';
import uploadMutImg from '@/component/uploadMutImg/index.vue';
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Upload);
Vue.use(Loading);
  export default {
    components: {
        uploadImg,
        integral,
        uploadMutImg
    },
    data() {
        const validateShowImg = (rule, value, callback) => {
            if (!this.data.showImg) {
                callback(new Error('请上传头像'));
            }
            callback();
        };
        return {
            rules: {
                showImg: [
                     { required: true, validator: validateShowImg  }
                ],
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur'  },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                bigClassificationId: [
                    { required: true, message: '请选择病虫害类型', trigger: 'blur' }
                ],
                midClassificationId: [
                    { required: true, message: '请选择虫害/病害类别', trigger: 'blur' }
                ],
                smallClassificationId: [
                    { required: true, message: '请选择详细类别', trigger: 'blur' }
                ],
                behaviorCharacteristics: [
                    { required: true, message: '请输入习性特点', trigger: 'blur' }
                ],
                symptom: [
                    { required: true,  message: '请输入危害状态及特点', trigger: 'blur' }
                ],
                happenRegularity: [
                     { required: true, message: '请输入发生规律', trigger: 'blur' }
                ],
                controlMethod: [
                     { required: true, message: '请输入防治方案', trigger: 'blur' }
                ]
            },
            data: {
                name: '', // 名称
                behaviorCharacteristics: '', // 习性特点
                symptom: '', // 危害状态及特点
                happenRegularity: '', // 发生规律
                controlMethod: '', // 防治方案
                bigClassificationId: '', // 大类id
                imgPaths: '', // imgPaths
                midClassificationId: '', // 中间类id
                showImg: '', // 头像
                smallClassificationId: '' // 小类id
            },
            param: {
                content: '',
                integral: 0,
                status: 0,
                type: 2,
                isPublic: 0
            },
            checked: true,
            selectIndex: -1,
            equipmentTypeList: [],
            timeOut: new Date(),
            moduleList: {
                module: 'dataShare',
                subModule: 'instrumentShare'
            },
            imgList: [],
            diseasesTypeList: [], // 病虫害类型
            speciesTypeList: [], // 虫害类别/病害类别
            detailTypeList: [],
            labAddress: { 
                disabled: false, // 只为了显示不能选择地址
                provinceId: '',
                cityId: '',
                districtId: ''
            }
        };
    },
    async mounted() {
        await this.getEquipmentTypeList();
        await this.getDiseasesType(0, 0);
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                alert('submit!');
            } else {
                // console.log('error submit!!');
                return false;
            }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        changeImg(file){
            this.data.showImg = file.filePath;
        },
        // 修改查看规则
        changeRules(data) {
            this.param.integral = data.integral;
            this.param.isPublic = data.isPublic;
            if (data.isPublic === 0) {
            this.checked = false;
            } else {
            this.checked = true;
            }
        },
        changeSelectIndex(data) {
            this.selectIndex = Number(data);
        },
        // 产品类别
        async getEquipmentTypeList() {
            const api = this.$fetchApi.equipmentTypeList.api;
            const data = await this.$fetchData.fetchPost({}, api, 'json');
            if (data.code === '200') {
                this.equipmentTypeList = data.result;
            }
        },
        // 保存
        onSaveInfo(formName) {
            this.operation(formName, 0);
        },
        // 提交
        onSubmitInfo(formName) {
            this.operation(formName, 1);
        },
        operation(formName, idx) {
            if (new Date() - this.timeOut < 1000) {
                return true;
            }
            this.timeOut = new Date();
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.param.isPublic === 0 && this.param.integral === 0) {
                        Message('请设置规则积分');
                        return false;
                    }
                    this.param.status = idx;
                    this.addRecord();
                } else {
                    window.scroll(0, 400);
                    return false;
                }
            });
        },
        // 保存和提交记录
        async addRecord() {
            const loadingInstance = Loading.service({
                fullscreen: true,
                background: 'rgba(0,0,0,.4)'
            });
            const obj = this.param;
            this.data.imgPaths = this.pictureCollation();
            obj.content = JSON.stringify(this.data);
            let api = '';
            if (this.$route.query && this.$route.query.update) {
                api = this.$fetchApi.updateRecord.api;
            } else {
                api = this.$fetchApi.addPestRecord.api;
            }
            const data = await this.$fetchData.fetchPost(obj, api, 'json');
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close();
            });
            if (data.code === '200') {
                if (data.result === 1) {
                    this.isSubmit = false;
                    Message('提交成功，请至app查看');
                }
            }
        },
        // 附件图片的整理
        pictureCollation() {
            let imgPaths = '';
            if (this.imgList.length > 0) {
                this.imgList.forEach((item, index) => {
                imgPaths += item;
                if (index < this.imgList.length - 1) {
                    imgPaths += ',';
                }
                });
            }
            return imgPaths;
        },
        changeImgList(fileList) {
            const files = [];
            if (fileList.length > 0) {
               fileList.forEach(item => {
                   files.push(item.filePath);
               });
            }
            this.imgList = files;
        },
        // 病虫害类型
        async getDiseasesType(id, type) {
            const api = this.$fetchApi.classification.api;
            const data = await this.$fetchData.fetchGet({},  api +  `/${id}`, 'json');
            if (data.code === '200') {
                if (type === 0) {
                    this.diseasesTypeList  = data.result;
                    this.speciesTypeList = [];
                    this.detailTypeList = [];
                    this.data.midClassificationId = '';
                    this.data.smallClassificationId = '';
                } else if (type === 1) {
                    this.speciesTypeList = data.result;
                    this.detailTypeList = [];
                    this.data.smallClassificationId = '';
                } else if (type === 2) {
                    this.detailTypeList = data.result;
                }
            }
        },
        changeBigClassificationId(data) {
            this.getDiseasesType(data, 1);
        },
        changeMidClassificationId(data) {
            this.getDiseasesType(data, 2);
        }
    }
  };
</script>
<style lang="less" scoped>
    @import './common.less';
</style>