<template>
    <div  class='contentData'>
        <el-form :model="data" :rules="rules" ref="expertData" label-width="140px" class="demo-data">
            <div class='flex dataCommon'>
                <el-form-item :label="data.expertType==2?'人才照片':'专家照片'" prop="photo">
                   <uploadImg @changeImg='changeImg'></uploadImg>
                </el-form-item>
                <el-form-item :label="data.expertType==2?'人才名称':'专家名称'" prop="name">
                    <el-input v-model="data.name" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="人才类型" prop="expertType">
                      <el-select v-model="data.expertType" placeholder="请选择">
                          <el-option label="专家" :value="1"></el-option>
                          <el-option label="人才" :value="2"></el-option>
                        </el-select>
                  </el-form-item>
                <el-form-item :label="data.expertType==2?'人才性别':'专家性别'" prop="sex">
                     <el-radio-group v-model="data.sex">
                        <el-radio label="1">男</el-radio>
                        <el-radio label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="联系方式" prop="phoneNumber">
                    <el-input v-model="data.phoneNumber" maxlength='11'></el-input>
                </el-form-item>
                <el-form-item label="专业" prop="professionalism">
                    <el-input v-model="data.professionalism" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="职务" prop="technicalTitle">
                    <el-input v-model="data.technicalTitle" maxlength='30'></el-input>
                </el-form-item>
                <el-form-item label="工作单位" prop="workUnit">
                    <el-input v-model="data.workUnit" maxlength='30'></el-input>
                </el-form-item>
                
            </div>
            <div class='contentBg'>
                <div v-for='(item, index) in baseInfo' :key='index'>
                    <div class="breedsTitle flex">
                        <span class='flex_length'>基地 ({{ index + 1 }})</span>
                        <div class='operation'>
                        <span class='delete' v-if="baseInfo.length > 1" @click="deleteBase(index)">删除</span>
                        <span class='add_breed'  @click="addBase">新增</span>
                        </div>
                    </div>
                    <el-form-item label="所属基地" prop="districtId">
                        <villageAddressSelect :ref='"villageAddress"+index' @villageInfo="villageInfo($event,index)" :cityDistrictVillage='cityDistrictVillage'></villageAddressSelect>
                    </el-form-item>
                    <div class='flex dataCommon'>
                        <el-form-item label="现有基地" prop="baseId">
                            <el-select v-model="item.baseId" placeholder="请选择">
                                <el-option
                                    v-for="item in item.base_columns"
                                    :key="item.id"
                                    :label="item.baseName"
                                    :value="item.id">
                                </el-option>
                                </el-select>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <el-form-item label="信息介绍" prop="content">
                <el-input v-model="data.content" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none'></el-input>
            </el-form-item>
            <integral v-bind:checked = 'checked' v-on:changeSelectIndex='changeSelectIndex($event)' v-bind:selectIndex = 'selectIndex' v-on:changeRules='changeRules($event)'></integral>
            <div class='submit'>
              <el-button @click='onSaveInfo("expertData")'>保存</el-button>
              <el-button class='submitData' @click='onSubmitInfo("expertData")'>提交</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
import Vue from 'vue';
import { Radio, RadioGroup,  Upload, Loading, Message } from 'element-ui';
import uploadImg from '@/component/uploadImg/index.vue';
import integral from './integral';
import villageAddressSelect from '@/component/villageAddressSelect/index.vue';
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Upload);
Vue.use(Loading);

  export default {
    components: {
        uploadImg,
        integral,
        villageAddressSelect
    },
    data() {
        const validatePhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入联系电话'));
            } else {
                const reg = /^1[3456789]\d{9}$/;
                if (!reg.test(this.data.phoneNumber)) {
                    callback(new Error('请输入正确的手机号码'));
                }
                callback();
            }
        };
        return {
            rules: {
                name: [
                    { required: true, message: '请输入专家名称', trigger: 'blur' },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                expertType: [
                    { required: true, message: '请选择人才类型', trigger: 'blur' }
                ],
                phoneNumber: [
                    { required: true, validator: validatePhone, trigger: 'blur' }
                ],
                professionalism: [
                    { required: true, message: '请输入专业', trigger: 'blur' },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                technicalTitle: [
                    { required: true, message: '请输入职务', trigger: 'blur' },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                workUnit: [
                    { required: true, message: '请输入工作单位', trigger: 'blur' },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                content: [
                    { required: true, message: '请输入信息介绍', trigger: 'blur' }
                ],
                sex: [{
                    required: true
                }]
            },
            data: {
                content: '', // 信息介绍
                workUnit: '', // 工作单位
                technicalTitle: '', // 职务
                professionalism: '', // 专业
                phoneNumber: '', // 电话
                sex: '1', // 性别
                photo: '', // 头像
                name: '', // 名称
                expertType: '', // 1 专家 2 人才
                baseId: '',
                category: '1',
                partTime: '',
                assessmentTime: ''
            },
            param: {
                content: '',
                integral: 0,
                status: 0,
                type: 3,
                isPublic: 0
            },
            checked: true,
            selectIndex: -1,
            timeOut: new Date(),
            base_columns: [],
            // baseInfo: {
            //     cityId: '',
            //     districtId: '',
            //     villageId: ''
            // },
            cityDistrictVillage: {
                disabled: false,
                proviceId: '',
                cityId: "",
                districtId: "",
                villagesId: ''
            },
            baseInfo: [{
                proviceId: '',
                cityId: '',
                districtId: '',
                villageId: '',
                baseId: '',
                base_columns: []
            }],
            villageAddressRef: ''
        };
    },
    methods: {
        changeImg(file){
            this.data.photo = file.filePath;
        },
      // 修改查看规则
        changeRules(data) {
            this.param.integral = data.integral;
            this.param.isPublic = data.isPublic;
            if (data.isPublic === 0) {
            this.checked = false;
            } else {
            this.checked = true;
            }
        },
        changeSelectIndex(data) {
            this.selectIndex = Number(data);
        },
        deleteBase(index) {
            this.villageAddressRef = 'villageAddress' + index;  
            this.$refs[`${this.villageAddressRef}`][0].provice = '';
            this.$refs[`${this.villageAddressRef}`][0].city = '';
            this.$refs[`${this.villageAddressRef}`][0].district = '';
            this.$refs[`${this.villageAddressRef}`][0].area = '';
            this.baseInfo.splice(index, 1);
        },
        addBase() {
            this.baseInfo.push({
                proviceId: '',
                cityId: '',
                districtId: '',
                villageId: '',
                baseId: '',
                baseName: '',
                base_columns: []
            });
        },

         // 保存
        onSaveInfo(formName) {
            this.operation(formName, 0);
        },
        // 提交
        onSubmitInfo(formName) {
            this.operation(formName, 1);
        },
         operation(formName, idx) {
            if (new Date() - this.timeOut < 1000) {
                return true;
            }
            this.timeOut = new Date();
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.param.isPublic === 0 && this.param.integral === 0) {
                        Message('请设置规则积分');
                        return false;
                    }
                    this.param.status = idx;
                    this.phoneHandal();
                } else {
                    window.scroll(0, 400);
                    return false;
                }
            });
        },
        // 保存和提交记录
        async addRecord() {
            const loadingInstance = Loading.service({
                fullscreen: true,
                background: 'rgba(0,0,0,.4)'
            });
            const obj = this.param;
            let baseIdStr = '';
            this.baseInfo.forEach((item) => {
                if (item.baseId) {
                    baseIdStr += item.baseId + ',';
                }
            });
            if (baseIdStr.length > 0) {
                baseIdStr = baseIdStr.substr(0, baseIdStr.length - 1);
            }
            this.data.baseId = baseIdStr;
            obj.content = JSON.stringify(this.data);
            let api = '';
            if (this.$route.query && this.$route.query.update) {
                api = this.$fetchApi.updateRecord.api;
            } else {
                api = this.$fetchApi.addExpertTalentRecord.api;
            }
            const data = await this.$fetchData.fetchPost(obj, api, 'json');
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close();
            });
            if (data.code === '200') {
                if (data.result === 1) {
                    this.isSubmit = false;
                    Message('提交成功，请至app查看');
                    // this.$router.push({
                    //     path: '/myDataShareDetail',
                    //     query: {
                    //     type: this.param.type,
                    //     active: 1
                    //     }
                    // });
                }
            }
        },
        // 手机校验
        async phoneHandal() {
            const api = this.$fetchApi.selPhone.api + this.data.phoneNumber;
            const data = await this.$fetchData.fetchPost({}, api, 'json');
            if (data.code === '200') {
                this.addRecord();
                return true;
            } else {
                return false;
            }
        },
        /** 返回市区村信息 */
        villageInfo(val, index) {
            if (val.length <= 2){
                this.baseInfo[index].baseId = '';
                this.baseInfo[index].base_columns = [];
                return;
            }
            const params = {
                pageNum: 1,
                pageSize: 1000,
                proviceId: '',
                cityId: '',
                districtId: '',
                villageId: ''
            };
            this.baseInfo[index].proviceId = val[0].id || '';
            this.baseInfo[index].cityId = val[1].id || '';
            this.baseInfo[index].districtId = val[2].id || '';
            this.baseInfo[index].villageId = val[3].id || '';
            this.baseInfo[index].baseId = '';

            params.proviceId = val[0].id || '';
            params.cityId = val[1].id || '';
            params.districtId = val[2].id || '';
            params.villageId = val[3].id || '';
            this.getBaseList(params, index);
        },
        /** 获取基地列表 */
        async getBaseList(params, index) {
            const api = this.$fetchApi.baseScreeningContent.api;
            const data = await this.$fetchData.fetchPost(params, api, 'json');
            if (data.code === '200') {
                const obj = [{
                    baseId: '',
                    baseName: '暂无数据'
                }];
                this.$set(this.baseInfo[index], 'base_columns', data.result ? data.result.rows : obj);
            }
        }
    }
  };
</script>
<style lang="less" scoped>
    @import './common.less';
  .breedsTitle{
        padding: 20px 110px 10px 30px;
      }
    .contentBg{
        width:1135px;
        background-color: #f6f6f6;
        border-radius: 4px;
        margin: 0 auto;
        padding-bottom:20px;
        margin-bottom:20px;
      }
      .operation{
      span{
        width: 80px;
        height: 36px;
        background-color: #ff5454;
        border-radius: 2px;
        display: inline-block;
        color:#fff;
        line-height: 36px;
        text-align: center;
      }
      .add_breed{
        background-color: #448afc;
        margin-left:20px;
      }
      
    }
</style>