<template>
    <div>
        <div class='ruleContent'>
        <div class='commonTitle flex'>
          <div class='rules'>查看规则设置</div>
          <div  class='flex_length'>
              <div v-if='isChecked'>
                <div class='flex flex_wrap scorList'>
                    <div class='scoreItem' :class='index == selectIndex ? "scoreActive" : ""' @click='onSocreItem(index, item)' v-for='(item, index) in scoreList' :key='index'>{{item}}分</div>
                </div>
             </div>
          </div>
          
          <div class='ruleSet'>{{isChecked ? '积分查看' : '公开'}}</div>
            <el-switch
                    v-model="isChecked"
                    :width = '39'
                    :height = '69'
                    active-color="#265ae1"
                    class='switch'
                    inactive-color="#ccc">
                </el-switch>
            </div>
        
      </div>
  
    </div>
</template>
<script>
import Vue from 'vue';
import { Switch } from 'element-ui';

Vue.use(Switch);
export default {
  props: ['checked', 'selectIndex'],
  data() {
    return {
      scoreList: [
        1, 2, 3, 4, 5
      ],
      rulesType: {
        isPublic: 0,
        integral: ''
      },
      isChecked: this.checked
    };
  },
  methods: {
    onSocreItem(idx, item) {
      this.rulesType.isPublic = 1;
      this.rulesType.integral = item;
      this.$emit('changeRules', this.rulesType);
      this.$emit('changeSelectIndex', idx);
    }
  },
  watch: {
    checked(val) {
      this.isChecked = val;
    },
    isChecked(val) {
      if (val) {
        this.rulesType.isPublic = 1;
        this.$emit('changeRules', this.rulesType);
      } else {
        const obj = {
          isPublic: 0,
          integral: ''
        };
        this.$emit('changeRules', obj);
      }
    }
  }
};
</script>
<style lang="less" scoped>
    .ruleContent{
  background: #fff;
}
  .commonTitle{
    color: #333;
    padding: 68px 0 144px;
    height:37px;
    line-height: 37px;
  }
  .rules{
      margin-right:63px;
  }

    .scoreItem{
        width: 73px;
        height: 34px;
        background-color: #f5f5f5;
        border-radius: 16px 16px 18px 16px;
        border: solid 1px #b4d0ff;
        color:#333;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
        margin-right:22px;
    }
    .scoreActive{
       background-color: #678be9;
       border: 1px solid #678be9;
       color: #fff;
    }
    .scorList{
      width:100%;
    }
    .switch{
      margin-left:20px;
      margin-right:30px;
    }
    .ruleSet{
      margin-left:20px;
    }
</style>