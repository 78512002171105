<template>
    <div>
        <div class='source'>
             说明：基地管理模块通过基地介绍、基地所属单位介绍、基地育种概况、基地成果一览、基地人员分布、人才队伍、产业发展、基地共享实验室、
基地其他展示、基地地图信息、基地实况监控等多个维度进行基地展示。
        </div>
        <!--  -->
        <el-form class='contentData' :rules="rules" :model="data"  ref="ruleForm" label-width="140px" >
             <!-- 基地基本信息 -->
             <div class='title clearfix'><span class='first fl'></span><span class='second fl'>基地基本信息</span></div>
             <div class='flex dataCommon'>
                <el-form-item label="基地头像" prop="imgUrl">
                    <uploadImg @changeImg='changeImg'></uploadImg>
                </el-form-item>
                <el-form-item label="基地名称" prop="baseName">
                    <el-input v-model="data.baseName" maxlength="30"  clearable></el-input>
                </el-form-item>
            </div>
            <el-form-item label="基地地址" prop="districtId">
                <villageAddressSelect @villageInfo="villageInfo" :cityDistrictVillage='cityDistrictVillage'></villageAddressSelect>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="基地详细地址" prop="baseAddress">
                    <el-input v-model="data.baseAddress" maxlength="30" clearable></el-input>
                </el-form-item>
                <el-form-item label="基地坐标-经度" prop="longitude">
                    <el-input v-model="data.longitude" clearable></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="基地坐标-纬度" prop="latitude">
                    <el-input v-model="data.latitude" clearable></el-input>
                </el-form-item>
                <el-form-item label="基地所属单位" prop="company">
                    <el-input v-model="data.company" maxlength="30" clearable></el-input>
                </el-form-item>
            </div>
            <el-form-item label="基地基本介绍" prop="baseDetail">
                <el-input v-model="data.baseDetail" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none' clearable></el-input>
            </el-form-item>
            <el-form-item label="基地所属单位介绍" prop="companyInfo">
                <el-input v-model="data.companyInfo" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none' clearable></el-input>
            </el-form-item>
            <!-- 育种概况板块信息收集 -->
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>育种概况板块信息收集</span></div>
             <div class='flex dataCommon'>
                <el-form-item label="种植地点" prop="plantSite">
                    <el-input v-model="data.plantSite"  maxlength="30" clearable></el-input>
                </el-form-item>
                <el-form-item label="占地面积" prop="area">
                    <el-input v-model="data.area" type="number"  maxlength="10" clearable></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="基地负责人" prop="person">
                    <el-input v-model="data.person" clearable></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="personPhone">
                    <el-input v-model="data.personPhone" maxlength="11" clearable></el-input>
                </el-form-item>
            </div>
            <div class='contentBg'>
              <div v-for='(item, index) in data.breeds' :key='index'>
                <div class="breedsTitle flex">
                  <span class='flex_length'>种植作物 ({{ index + 1 }})</span>
                  <div class='operation'>
                    <span class='delete' v-if="data.breeds.length > 1" @click="deleteBreeding(index)">删除</span>
                    <span class='add_breed'  @click="addBreeding">新增</span>
                  </div>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="作物名称" prop="plantName">
                    <el-input v-model="item.plantName" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="育种项目" prop="breedProject">
                      <el-input v-model="item.breedProject" clearable></el-input>
                  </el-form-item>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="种植面积" prop="plantArea">
                      <el-input v-model="item.plantArea" clearable></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>

              <!-- 成果一览板块信息收集 -->
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>成果一览板块信息收集</span></div>
              <div class='contentBg'>
                <div v-for='(item, index) in data.achievements' :key='index+"a"'>
                  <div class="breedsTitle flex">
                    <span class='flex_length'>成果名称 ({{ index + 1 }})</span>
                    <div class='operation'>
                        <span class='delete' v-if="data.achievements.length > 1" @click="deleteProductName(index)">删除</span>
                        <span class='add_breed'  @click="addProductName">新增</span>
                      </div>
                  </div>
                  <div class='flex dataCommon'>
                    <el-form-item label="成果名称" prop="achievementsName">
                        <el-input v-model="item.achievementsName" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="获奖级别" prop="name">
                        <el-select v-model="item.awardId" placeholder="请选择">
                          <el-option
                            v-for="item in awardList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                          </el-option>
                        </el-select>
                    </el-form-item>
                  </div>
                  <div class='flex dataCommon'>
                    <el-form-item label="获奖等级" prop="name">
                      <el-input v-model="item.awardLevelId" clearable></el-input>
                        <!-- <el-select v-model="item.awardLevelId" placeholder="请选择">
                          <el-option
                            v-for="item in awardLevelList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                          </el-option>
                        </el-select> -->
                    </el-form-item>
                    <el-form-item label="获奖人员" prop="winPersonnel">
                        <el-input v-model="item.winPersonnel" clearable></el-input>
                    </el-form-item>
                  </div>
                  <div class='flex dataCommon'>
                    <el-form-item label="获奖名称" prop="winName">
                        <el-input v-model="item.winName" type="number" maxlength="4" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="获奖年度" prop="year">
                        <el-input v-model="item.year" clearable></el-input>
                    </el-form-item>
                  </div>
                  <div class='flex dataCommon'>
                    <el-form-item label="获奖时间" prop="winDate">
                         <el-date-picker
                          v-model="item.winDate"
                          @change='changWinDate($event, index)'
                          type="date"
                          format="yyyy-MM-dd"
                          placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="本所排名" prop="id">
                        <el-select v-model="item.rankId" placeholder="请选择">
                          <el-option
                            v-for="item in awardRankList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                          </el-option>
                        </el-select>
                    </el-form-item>

                  </div>
                  <div class='flex dataCommon'>
                    <el-form-item label="获奖经历" prop="winExperience">
                        <el-input v-model="item.winExperience" clearable></el-input>
                    </el-form-item>
                  </div>
                  <el-form-item label="详情" prop="achievementsInfo">
                    <el-input v-model="item.achievementsInfo" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none' clearable></el-input>
                  </el-form-item>
                </div>
              </div>
             <!-- 专利名称 -->
             <div class='contentBg padding'>
                <div v-for='(item,index) in data.patents' :key='index+"p"'>
                <div class="breedsTitle flex">
                  <span class='flex_length'>专利名称 ({{ index + 1 }})</span>
                  <div class='operation'>
                      <span class='delete' v-if="data.patents.length > 1" @click="deletePatents(index)">删除</span>
                      <span class='add_breed'  @click="addpatents">新增</span>
                    </div>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="专利发明名称" prop="patentName">
                      <el-input v-model="item.patentName" maxlength="30" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="专利类别" prop="name">
                      <el-select v-model="item.patentCategoryId" placeholder="请选择">
                          <el-option
                            v-for="item in patentCategoryList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                          </el-option>
                        </el-select>
                  </el-form-item>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="授权时间" prop="authorizedTime">
                    <el-date-picker
                        v-model="item.authorizedTime"
                        @change='changAuthorizedTime($event,index)'
                        type="date"
                        format="yyyy-MM-dd"
                        placeholder="选择日期">
                      </el-date-picker>
                  </el-form-item>
                  <el-form-item label="标准编号" prop="patentNo">
                      <el-input v-model="item.patentNo" maxlength="30" clearable></el-input>
                  </el-form-item>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="发明人/设计人" prop="inventor">
                      <el-input v-model="item.inventor" type="number" maxlength="30" clearable></el-input>
                  </el-form-item>
                </div>
                <el-form-item label="详情" prop="patentDetail">
                  <el-input v-model="item.patentDetail" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none' clearable></el-input>
                </el-form-item>
              </div>
             </div>

            <!-- 标准名称（1） -->
            <div class='contentBg padding'>
              <div v-for='(item,index) in data.standards' :key='index+"s"'>
                <div class="breedsTitle flex">
                  <span class='flex_length'>标准名称 ({{ index + 1 }})</span>
                  <div class='operation'>
                    <span class='delete' v-if="data.standards.length > 1" @click="deleteStandards(index)">删除</span>
                    <span class='add_breed'  @click="addStandards">新增</span>
                  </div>
                </div>
                <div class='flex dataCommon'>

                  <el-form-item label="标准类别" prop="name">
                      <el-select v-model="item.standardCategoryId" placeholder="请选择">
                          <el-option
                            v-for="item in standardCtgList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                          </el-option>
                        </el-select>
                  </el-form-item>

                  <el-form-item label="国家标准名称" prop="standardName">
                      <el-input v-model="item.standardName" clearable></el-input>
                  </el-form-item>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="起草人" prop="draftsman">
                      <el-input v-model="item.draftsman" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="实施时间" prop="implementationTime">
                      <el-date-picker
                        v-model="item.implementationTime"
                        type="date"
                        format="yyyy-MM-dd"
                        @change='changImplementationTime($event,index)'
                        placeholder="选择日期">
                      </el-date-picker>
                  </el-form-item>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="专利号" prop="standardNo">
                      <el-input v-model="item.standardNo" type="number" maxlength="4" clearable></el-input>
                  </el-form-item>
                </div>
                <el-form-item label="详情" prop="standardInfo">
                  <el-input v-model="item.standardInfo" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none' clearable></el-input>
                </el-form-item>
              </div>
            </div>
            <!-- 人才分布数据收集 -->
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>人才分布数据收集</span></div>
            <div class='contentBg'>
              <div v-for='(item, index) in data.roles' :key='index+"r"'>
                <div class="breedsTitle flex">
                    <span class='flex_length'>数据收集 ({{ index + 1 }})</span>
                    <div class='operation'>
                      <span class='delete' v-if="data.roles.length > 1" @click="deleteRoles(index)">删除</span>
                      <span class='add_breed'  @click="addRoles">新增</span>
                    </div>
                  </div>
                <div class='flex dataCommon'>
                  <el-form-item label="人员职位" prop="roleName">
                      <!-- <el-input v-model="item.roleName" clearable></el-input> -->
                      <el-select v-model="item.roleId" placeholder="请选择" @change="choseRank($event,index)">
                          <el-option
                            v-for="item in rolesList"
                            :key="item.id"
                            :label="item.roleName"
                            :value="item.id">
                          </el-option>
                        </el-select>
                  </el-form-item>
                  <el-form-item label="人员数量" prop="count">
                      <el-input v-model="item.count" clearable></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>

            <!-- 人才队伍数据收集 -->
             <div class='title clearfix'><span class='first fl'></span><span class='second fl'>人才队伍数据收集</span></div>
             <div class='contentBg'>
              <div v-for='(item,index) in data.experts' :key='index+"e"'>
                <div class="breedsTitle flex">
                  <span class='flex_length'>人才/专家 ({{ index + 1 }})</span>
                  <div class='operation' >
                    <span class='delete' v-if="data.experts.length > 1" @click="deleteExperts(index)">删除</span>
                    <span class='add_breed' @click="addExperts">新增</span>
                  </div>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="人才类型" prop="expertType">
                      <el-select v-model="item.expertType" placeholder="请选择">
                          <el-option label="专家" :value="1"></el-option>
                          <el-option label="人才" :value="2"></el-option>
                        </el-select>
                  </el-form-item>
                  <el-form-item label="人才/专家名称" prop="name">
                      <el-input v-model="item.name" clearable></el-input>
                  </el-form-item>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="性别" prop="name">
                    <el-select v-model="item.sex" placeholder="请选择">
                      <el-option label="男" :value="1"></el-option>
                      <el-option label="女" :value="2"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="工作单位" prop="workUnit">
                      <el-input v-model="item.workUnit" clearable></el-input>
                  </el-form-item>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="职务" prop="technicalTitle">
                      <el-input v-model="item.technicalTitle" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="专业" prop="professionalism">
                      <el-input v-model="item.professionalism" clearable></el-input>
                  </el-form-item>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="联系方式" prop="phoneNumber">
                      <el-input v-model="item.phoneNumber" maxlength="11" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="信息介绍" prop="content">
                      <el-input v-model="item.content" clearable></el-input>
                  </el-form-item>
                </div>
                </div>
             </div>

             <!-- 基地产业发展数据收集  -->
             <div class='title clearfix'><span class='first fl'></span><span class='second fl'>基地产业发展数据收集 </span></div>
              <div class='flex dataCommon' v-if='data.induDevInsertReq!==null'>
                <el-form-item label="标题" prop="title">
                    <el-input v-model="data.induDevInsertReq.title" clearable></el-input>
                </el-form-item>
                <el-form-item label="正文" prop="name">
                    <el-input v-model="data.induDevInsertReq.detail" clearable></el-input>
                </el-form-item>
            </div>
             <!-- 基地共享实验室数据收集 -->
             <div class='title clearfix'><span class='first fl'></span><span class='second fl'>基地共享实验室数据收集</span></div>
              <div v-if='data.laboratory!==null'>
                <div class='flex dataCommon'>
                  <el-form-item label="实验室名称" prop="name">
                      <el-input v-model="data.laboratory.name" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="联系方式" prop="contactNumber">
                      <el-input v-model="data.laboratory.contactNumber" maxlength="11" clearable></el-input>
                  </el-form-item>
                </div>
                <el-form-item label="实验室地址" prop="name">
                  <addressSelect @addressInfo='addressInfo' :address="labAddress" ></addressSelect>
                </el-form-item>
                <div class='flex dataCommon'>
                    <el-form-item label="详细地址" prop="laboratoryAddress">
                        <el-input v-model="data.laboratory.laboratoryAddress" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="依托单位" prop="relyonCompany">
                        <el-input v-model="data.laboratory.relyonCompany" clearable></el-input>
                    </el-form-item>
                </div>
                <div class='flex dataCommon'>
                    <el-form-item label="服务范围" prop="serviceScope">
                        <el-input v-model="data.laboratory.serviceScope" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="官网" prop="websiteUrl">
                      <el-input v-model="data.laboratory.websiteUrl" clearable></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="实验室仪器" prop="laboratoryInstrument">
                    <el-input v-model="data.laboratory.laboratoryInstrument" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none' clearable></el-input>
                </el-form-item>
                <el-form-item label="可开展子项目" prop="promoteItem">
                    <el-input v-model="data.laboratory.promoteItem" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none' clearable></el-input>
                </el-form-item>
                <el-form-item label="介绍" prop="introduce">
                    <el-input v-model="data.laboratory.introduce" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none' clearable></el-input>
                </el-form-item>
              </div>
            <!-- 基地其他室数据收集  -->
             <div class='title clearfix'><span class='first fl'></span><span class='second fl'>基地其他室数据收集 </span></div>
              <div class='flex dataCommon' v-if='data.otherDevInsertReq!==null'>
                <el-form-item label="标题" prop="title">
                    <el-input v-model="data.otherDevInsertReq.title" clearable></el-input>
                </el-form-item>
                <el-form-item label="正文" prop="detail">
                    <el-input v-model="data.otherDevInsertReq.detail" clearable></el-input>
                </el-form-item>
            </div>
            <integral v-bind:checked = 'checked' v-on:changeSelectIndex='changeSelectIndex($event)' v-bind:selectIndex = 'selectIndex' v-on:changeRules='changeRules($event)'></integral>
            <div class='submit'>
              <el-button @click='onSaveInfo("ruleForm")'>保存</el-button>
              <el-button class='submitData' @click='onSubmitInfo("ruleForm")'>提交</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
import Vue from 'vue';
import { Radio, RadioGroup, Upload, DatePicker, Message, Loading } from 'element-ui';
import uploadImg from '@/component/uploadImg/index.vue';
import villageAddressSelect from '@/component/villageAddressSelect/index.vue';
import integral from './integral';
import { yyyymmdd, filter_empty_value } from '@/utils/utils';
import addressSelect from '@/component/addressSelect/index.vue';


Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Upload);
Vue.use(DatePicker);
Vue.use(Loading);
const msg ='已达到新增上限';
  export default {
    components: {
        uploadImg,
        villageAddressSelect,
        integral,
        addressSelect
    },
    data() {
      const validateImg = (rule, value, callback) => {
        if (!this.data.imgUrl) {
          callback(new Error('请上传图片'));
        }
         callback();
      };
      const validateLng = (rule, value, callback) => {
        if (value === '') {
            callback(new Error('请填写基地经度'));
        } else {
            const reg =  /^(-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/;
            if (!reg.test(this.data.longitude)) {
                callback(new Error('经度整数部分为0-180,小数部分为0到6位'));
            }
            callback();
        }
      };
      const validateLat = (rule, value, callback) => {
        if (value === '') {
            callback(new Error('请填写基地纬度'));
        } else {
            const regLat = /^(-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/;
            if (!regLat.test(this.data.latitude)) {
                callback(new Error('纬度整数部分为0-90,小数部分为0到6位'));
            }
            callback();
        }
      };
      const validateAddress = (rule, value, callback) => {
        if (this.data.cityId === '') {
            callback(new Error('请选择基地地址'));
        }
         callback();
      };
      return {
        rules: {
          imgUrl: [{
            required: true, validator: validateImg
          }],
          baseName: [
            { required: true, message: '请填写基地名称', trigger: 'blur', type: 'string' }
          ],
          baseAddress: [
            { required: true, message: '请填写基地详细地址', trigger: 'blur', type: 'string' }
          ],
          longitude: [
            { validator: validateLng, required: true, trigger: 'blur' }
          ],
          latitude: [
            { validator: validateLat, required: true,  trigger: 'blur' }
          ],
          baseDetail: [
            { required: true, message: '请填写所属单位', trigger: 'blur', type: 'string' }
          ],
          company: [
            { required: true, message: '请填写基地所属单位介绍', trigger: 'blur', type: 'string' }
          ],
          companyInfo: [
            { required: true, message: '请填写基地所属单位介绍', trigger: 'blur', type: 'string' }
          ],
          districtId: [
             { required: true, validator: validateAddress, type: 'string' }
          ]
        },
        data: {
          // 成果数据
          achievements: [
            {
              achievementsInfo: '', // 详情
              achievementsName: '', // 成果名称
              appendixDocId: '', // ID pdf附件,多个以逗号分隔
              appendixPicId: '', // ID 图片附件,多个以逗号隔开
              awardId: '', // 获奖级别id(国家级、行业级....)
              awardLevelId: '', // 获奖等级id(一等奖、二等奖…)
              baseId: '', // 基地id(不需传递)
              rankId: '', // 本所排名id
              winDate: '', // 获奖时间 yyyy-MM-dd
              winExperience: '', // 获奖经历
              winName: '', // 获奖名称
              winPersonnel: '', // 获奖人员
              year: '' // 年度
            }
          ],
          area: 0, // 基地占地面积 (最多10位)
          provinceId: '',
          baseAddress: '', // 基地地址--详细地址
          baseDetail: '', // 基地基本介绍
          baseName: '', // 基地名称（全称）
          // 育种数据（breed）
          breeds: [
            {
              breedProject: '', // 育种项目
              plantArea: 0, // 种植面积
              plantName: '' // 植物名称
            }
          ],
          cityId: '', // 基地地址--市id
          company: '', // 所属单位
          companyInfo: '', // 所属单位基本介绍
          districtId: '', // 基地地址--区id
          // 人才队伍信息（expert）
          experts: [
            {
              baseId: '', // 基地id(不需传递)
              content: '', // 介绍
              expertType: '', // 类型 1专家 2人才
              name: '', // 姓名
              phoneNumber: '', // 联系方式
              photo: '', // 头像
              professionalism: '', // 专业/从事专业
              sex: '', // 性别 1 男 2 女
              technicalTitle: '', // 职务/技术职称
              workUnit: '' // 工作单位
            }
          ],
          imgUrl: '', // 基地头像 -- 地址（图片）
          // 基地产业发展数据收集
          induDevInsertReq: {
            baseId: '', // 基地id(不需传递)
            detail: '', // 详情
            imgUrl: '', // 图片url 一张图片
            title: '' // 标题
          },
          // 共享实验室（laboratory）
          laboratory: {
            baseId: '', // 基地id(不需传递)
            contactNumber: '', // 联系电话
            id: '', // id(不传递)
            imageUrl: '', // 多个图片逗号分割 url1,url2,url3
            introduce: '', // 介绍
            laboratoryAddress: '', // 地址
            laboratoryArea: '', // 区 id
            laboratoryCity: '', // 市 id
            laboratoryInstrument: '', // 实验室仪器
            laboratoryProvince: '', // 省id
            name: '', // 实验室名称
            promoteItem: '', // 可开展子项目
            relyonCompany: '', // 依托单位
            serviceScope: '', // 服务范围
            websiteUrl: '' // 官网
          },
          latitude: '', // 基地坐标 -- 纬度
          longitude: '', // 基地坐标 -- 经度
          // 基地其它展示数据收集
          otherDevInsertReq: {
            baseId: '', // 基地id(不需传递)
            detail: '', // 详情
            imgUrl: '', // 图片url 一张图片
            title: '' // 标题
          },
          // 授权专利（patent）
          patents: [
            {
              appendixDocId: '', // ID pdf附件,多个以逗号分隔
              appendixPicId: '', // ID 图片附件,多个以逗号隔开

              authorizedTime: '', // 授权时间
              baseId: '', // 基地id
              inventor: '', // 发明人/设计人
              patentCategoryId: '', // 专利类别(发明专利、实用新型专利...)
              patentDetail: '', // 详情
              patentName: '', // 专利名称
              patentNo: '' // 标准编号/专利号
            }
          ],
          person: '', // 基地负责人
          personPhone: '', // 联系方式
          plantSite: '', // 种植地点
          // 制定标准（standard）
          standards: [
            {
              appendixDocId: '', // ID pdf附件,多个以逗号分隔
              appendixPicId: '', // ID 图片附件,多个以逗号隔开

              baseId: '', // 基地id
              draftsman: '', // 起草人
              implementationTime: '', // 实施时间
              standardCategoryId: '', // 标准类别id（国家标准、行业标准...）
              standardInfo: '', // 详情
              standardName: '', // 标准名称
              standardNo: '' // 专利号/实施编号
            }
          ],
          teamIntroduced: '', // 队伍介绍
          urlName: '', // 基地头像 -- 图片名称（图片）
          villageId: '', // 基地地址--村id
          roles: [{
            count: '',
            roleId: '',
            roleName: ''
          }]
        },
        param: {
          content: '',
          integral: 0,
          status: 0,
          type: 1,
          isPublic: 0
        },
        awardLevelList: [], // 获奖等级列表
        awardList: [], // 查询获奖级别
        awardRankList: [], // 排名
        patentCategoryList: [], // 专利类别
        standardCtgList: [], // 标准类别
        rolesList: [],
        timeOut: new Date(),
        checked: true,
        selectIndex: -1,
        currentDate: new Date(),
        start_show: true,
        isSubmit: false, // 是否有添加 提交 保存
        personPhoneError: '',
        contactNumberError: '',
        phoneNumberError: [],
        labAddress: {
          disabled: false,
          provinceId: '',
          cityId: '',
          districtId: ''
        },
        cityDistrictVillage: {
          disabled: false,
          cityId: "",
          districtId: "",
          villagesId: ''
        }
      };
    },
    mounted() {
      // this.getAwardLevel();
      this.getAward();
      this.getAwardRank();
      this.getStandardCategory();
      this.getPatentCategory();
      this.getRoleTypeList();
    },
    methods: {
      changeImg(file){
          this.data.imgUrl = file.filePath;
          this.data.urlName = file.name;
      },
      /** 增加育种类型 */
      addBreeding() {
        if (this.data.achievements.length > 10) {
          Message(msg);
          return;
        }
        this.data.breeds.push({
          breedProject: '', // 育种项目
          plantArea: 0, // 种植面积
          plantName: '' // 植物名称
        });
      },
      // 新增成果
      addProductName() {
        if (this.data.achievements.length > 12) {
          Message(msg);
          return;
        }
        this.data.achievements.push({
          achievementsInfo: '', // 详情
          achievementsName: '', // 成果名称
          appendixDocId: '', // ID pdf附件,多个以逗号分隔
          appendixPicId: '', // ID 图片附件,多个以逗号隔开
          awardId: '', // 获奖级别id(国家级、行业级....)
          awardLevelId: '', // 获奖等级id(一等奖、二等奖…)
          baseId: '', // 基地id(不需传递)
          rankId: '', // 本所排名id
          winDate: '', // 获奖时间 yyyy-MM-dd
          winExperience: '', // 获奖经历
          winName: '', // 获奖名称
          winPersonnel: '', // 获奖人员
          year: '' // 年度
        });
      },
      // 新增专利
      addpatents() {
        if (this.data.patents.length > 12) {
          Message(msg);
          return;
        }
        this.data.patents.push({
          appendixDocId: '', // ID pdf附件,多个以逗号分隔
          appendixPicId: '', // ID 图片附件,多个以逗号隔开
          authorizedTime: '', // 授权时间
          baseId: '', // 基地id
          inventor: '', // 发明人/设计人
          patentCategoryId: '', // 专利类别(发明专利、实用新型专利...)
          patentDetail: '', // 详情
          patentName: '', // 专利名称
          patentNo: '' // 标准编号/专利号
        });
      },
      // 新增标准
      addStandards() {
        if (this.data.standards.length > 12) {
          Message(msg);
          return;
        }
        this.data.standards.push({
          appendixDocId: '', // ID pdf附件,多个以逗号分隔
          appendixPicId: '', // ID 图片附件,多个以逗号隔开

          baseId: '', // 基地id
          draftsman: '', // 起草人
          implementationTime: '', // 实施时间
          standardCategoryId: '', // 标准类别id（国家标准、行业标准...）
          standardInfo: '', // 详情
          standardName: '', // 标准名称
          standardNo: '' // 专利号/实施编号
        });
      },
      // 增加角色
      addRoles() {
        if (this.data.roles.length > 12) {
          Message(msg);
          return;
        }
        this.data.roles.push({
          count: '',
          roleId: '',
          roleName: ''
        });
      },
      addExperts() {
        if (this.data.experts.length > 12) {
          Message(msg);
          return;
        }
        this.data.experts.push({
          baseId: '', // 基地id(不需传递)
          content: '', // 介绍
          expertType: 0, // 类型 1专家 2人才
          name: '', // 姓名
          phoneNumber: '', // 联系方式
          photo: '', // 头像
          professionalism: '', // 专业/从事专业
          sex: 0, // 性别 1 男 2 女
          technicalTitle: '', // 职务/技术职称
          workUnit: '' // 工作单位
        });
      },
      /** 删除育种类型 */
      deleteBreeding(index) {
        this.data.breeds.splice(index, 1);
      },
      deleteProductName(index) {
        this.data.achievements.splice(index, 1);
      },
      deletePatents(index) {
        this.data.patents.splice(index, 1);
      },
      deleteStandards(index) {
        this.data.standards.splice(index, 1);
      },
      deleteRoles(index) {
        this.data.roles.splice(index, 1);
      },
      deleteExperts(index) {
        this.data.experts.splice(index, 1);
      },
      /** 返回市区村信息 */
      villageInfo(val) {
        this.data.provinceId = val[0].id ? val[0].id : '';
        this.data.cityId = val[1].id ? val[1].id : '';
        this.data.districtId = val[2].id ? val[2].id : '';
        this.data.villageId = val[3].id ? val[3].id : '';
      },
      // 获奖等级列表
      async getAwardLevel() {
        const api = this.$fetchApi.awardLevel.api;
        const data = await this.$fetchData.fetchPost({}, api, 'json');
        if (data.code === '200' && data.result) {
          this.awardLevelList = data.result;
        }
      },
      // 查询获奖级别/成果奖项
      async getAward() {
        const api = this.$fetchApi.award.api;
        const data = await this.$fetchData.fetchPost({}, api, 'json');
        if (data.code === '200' && data.result) {
          this.awardList = data.result;
        }
      },
      // 查询排名
      async getAwardRank() {
        const api = this.$fetchApi.awardRank.api;
        const data = await this.$fetchData.fetchPost({}, api, 'json');
        if (data.code === '200' && data.result) {
          this.awardRankList = data.result;
        }
      },
      // 人员职位
      async getRoleTypeList() {
        const api = this.$fetchApi.getRoleTypeList.api;
        const data = await this.$fetchData.fetchPost({}, api, 'json');
        if (data.code === '200' && data.result) {
          this.rolesList = data.result;
        }
      },
      // 专利类别列表
      async getPatentCategory() {
        const api = this.$fetchApi.patentCategory.api;
        const data = await this.$fetchData.fetchPost({}, api, 'json');
        if (data.code === '200' && data.result) {
          this.patentCategoryList = data.result;
        }
      },
      // 查询标准类别
      async getStandardCategory() {
        const api = this.$fetchApi.standardCategory.api;
        const data = await this.$fetchData.fetchPost({}, api, 'json');
        if (data.code === '200' && data.result) {
          this.standardCtgList = data.result;
        }
      },
       /** 返回三级地址信息 */
      addressInfo(val) {
        this.data.laboratory.laboratoryProvince = val[0].id; // 居住地址 省id
        this.data.laboratory.laboratoryCity = val[1].id;         // 居住地址 市id
        this.data.laboratory.laboratoryArea = val[2].id; // 居住地址 区id
      },
      choseRank(data, idx) {
        this.rolesList.forEach(item => {
          if ( item.id === data) {
            this.$set(this.data.roles[idx], 'roleName', item.roleName);
          }
        });
      },
      // 获奖时间
      changWinDate(data, index) {
        this.$set(this.data.achievements[index], 'winDate', yyyymmdd(data));
      },
      // 授权时间
      changAuthorizedTime(data, index) {
        this.$set(this.data.patents[index], 'authorizedTime', yyyymmdd(data));
      },
      // 实施时间
      changImplementationTime(data, index) {
        this.$set(this.data.standards[index], 'implementationTime', yyyymmdd(data));
      },
      // 修改查看规则
      changeRules(data) {
        this.param.integral = data.integral;
        this.param.isPublic = data.isPublic;
        if (data.isPublic === 0) {
          this.checked = false;
        } else {
          this.checked = true;
        }
      },
      changeSelectIndex(data) {
        this.selectIndex = Number(data);
      },
      // 保存
      onSaveInfo(formName) {
        this.operation(formName, 0);
      },
      // 提交
      onSubmitInfo(formName) {
        this.operation(formName, 1);
      },
      operation(formName, idx) {
        if (new Date() - this.timeOut < 1000) {
          return true;
        }
        this.timeOut = new Date();
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.param.isPublic === 0 && this.param.integral === 0) {
              Message('请设置规则积分');
              return false;
            }
            this.param.status = idx;
            this.addRecord();
          } else {
            window.scroll(0, 400);
            return false;
          }
        });
      },
      // 保存和提交记录
      async addRecord() {
        const loadingInstance = Loading.service({
          fullscreen: true,
          background: 'rgba(0,0,0,.4)'
        });
        const obj = this.param;
        // 实验室
        const dataObj =  JSON.parse(JSON.stringify(this.data)) ;
        const laboratory = filter_empty_value(dataObj.laboratory);
        // 数据
        const induDevInsertReq = filter_empty_value(dataObj.induDevInsertReq);
        // 其他数据
        const otherDevInsertReq = filter_empty_value(dataObj.otherDevInsertReq);
        if (JSON.stringify(laboratory) === '{}') {
          dataObj.laboratory = null;
        }
        if (JSON.stringify(induDevInsertReq) === '{}') {
          dataObj.induDevInsertReq = null;
        }
        if (JSON.stringify(otherDevInsertReq) === '{}') {
          dataObj.otherDevInsertReq = null;
        }
        dataObj.achievements = this.filterEmptyValue(dataObj.achievements);
        dataObj.breeds = this.filterEmptyValue(dataObj.breeds);
        dataObj.experts = this.filterEmptyValue(dataObj.experts);
        dataObj.patents = this.filterEmptyValue(dataObj.patents);
        dataObj.standards = this.filterEmptyValue(dataObj.standards);
        dataObj.roles = this.filterEmptyValue(dataObj.roles);
        obj.content = JSON.stringify(dataObj);
        let api = '';
        if (this.$route.query && this.$route.query.update) {
          api = this.$fetchApi.updateRecord.api;
        } else {
          api = this.$fetchApi.addBaseRecord.api;
        }
        const data = await this.$fetchData.fetchPost(obj, api, 'json');
        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
        if (data.code === '200') {
          if (data.result === 1) {
            this.isSubmit = false;
             window.scroll(0, 400);
            Message('提交成功，请至app查看');
            // this.$router.replace({
            //   path: '/myDataShareDetail',
            //   query: {
            //     type: 1
            //   }
            // });
          }
        }
      },
      // 过滤字段
      filterEmptyValue(data) {
        const achievementsArr = [];
        // 成果数据
        data.forEach((item) => {
          const achievementsItem = filter_empty_value(item, true);
          if (JSON.stringify(achievementsItem) !== '{}') {
            achievementsArr.push(item);
          }
        });
        if (achievementsArr.length > 0) {
          return achievementsArr;
        } else {
          return [];
        }
      }
    }
  };
</script>
<style lang="less" scoped>
@import './common.less';

    .source{
      width: 1140px;
      background-color: #b4d1ff;
      border-radius: 4px;
      font-size: 18px;
      color: #448afc;
      padding: 20px;
      margin: 0 auto;
    }
    .operation{
      span{
        width: 80px;
        height: 36px;
        background-color: #ff5454;
        border-radius: 2px;
        display: inline-block;
        color:#fff;
        line-height: 36px;
        text-align: center;
      }
      .add_breed{
        background-color: #448afc;
        margin-left:20px;
      }

    }
    .breedsTitle{
        padding: 20px 110px 10px 30px;
      }
    .contentBg{
        width:1135px;
        background-color: #f6f6f6;
        border-radius: 4px;
        margin: 0 auto;
        padding-bottom:20px;
      }
      .padding{
        margin-top:20px;
      }


</style>
