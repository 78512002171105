<template>
    <div>
         <div class='flex justify_content_between'>
            <input type="file" ref="file_input" @change="fileChange($event)" hidden>
            <div v-if='data.photo' class='padding' @click="triggerFileInput">
              <img id="img-pic"  :src="data.photo" alt="">
            </div>
            <div v-else  class='padding'>
            <div class='addImg flex flex_direction_column justify_content_center' @click="triggerFileInput">
                <img :src="require('@/assets/images/tjzp.icon@3x.png')" alt="">
                <div>添加图片</div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
const imgTypes = ['jpg', 'jpeg', 'png', 'gif'];
import { Message } from 'element-ui';
export default {
    data() {
        return {
            data: {
                photo: ''
            }
        };
    },
    methods: {
        /** 触发选择文件 */
        triggerFileInput() {
            const input = this.$refs.file_input;
            if (input && input.click) { input.click(); }
        },
        // 上传图片
        async fileChange(ev) {
            if (!ev.target.value) { return; }
            const file = ev.target.files[0];
            const arr = file.name.split('.');
            const file_type = arr[arr.length - 1];
            if (!imgTypes.includes(file_type.toLowerCase())) {
                // Toast('请上传图片');
                Message('请上传图片');
                return;
            }
            const api = this.$fetchApi.uploadFile.api;
            const formdata = new FormData();

            formdata.append('files', file);
            formdata.append('module', 'emphasisPerson');
            formdata.append('subModule', 'emphasisPersonAdd');
            const data = await this.$fetchData.fetchPost(formdata, api, 'file');

            if (data.code === '200') {
                this.data.photo = data.result[0].filePath;
                this.$emit('changeImg', data.result[0]);
            }
        }
    }
};
</script>
<style lang="less" scoped>
    .addImg{
      width:150px;
      height:150px;
      border:1px solid #ccc;
  }
  #img-pic{
      width:150px;
      height:150px;
  }
</style>