<template>
    <div class='contentData'>
        <el-form :model="data" :rules="rules" ref="pestDataShare" label-width="140px" class="demo-data">
            <div class='flex dataCommon'>
                <el-form-item label="实验室照片" prop="imageUrl">
                   <uploadImg @changeImg='changeImg'></uploadImg>
                </el-form-item>
                <el-form-item label="实验室名称" prop="name">
                    <el-input v-model="data.name" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <el-form-item label="实验室地址" prop="laboratoryProvince">
                <addressSelect @addressInfo='addressInfo' :address='labAddress'></addressSelect>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="详细地址" prop="laboratoryAddress">
                    <el-input v-model="data.laboratoryAddress" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <el-form-item label="所属基地" prop="deviceApplyProvince">
               <villageAddressSelect @villageInfo="villageInfo" :cityDistrictVillage='cityDistrictVillage'></villageAddressSelect>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="现有基地" prop="baseId">
                    <el-select v-model="data.baseId" placeholder="请选择">
                        <el-option
                        v-for="item in base_columns"
                        :key="item.id"
                        :label="item.baseName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系方式" prop="contactNumber">
                    <el-input v-model="data.contactNumber" maxlength='11'></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="依托单位" prop="relyonCompany">
                    <el-input v-model="data.relyonCompany"  maxlength="30"></el-input>
                </el-form-item>
                <el-form-item label="服务范围" prop="serviceScope">
                    <el-input v-model="data.serviceScope" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="官网" prop="websiteUrl">
                    <el-input v-model="data.websiteUrl" maxlength='30'></el-input>
                </el-form-item>
                <el-form-item label="实验室仪器" prop="promoteItem">
                    <el-input v-model="data.promoteItem" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="可开展子项目" prop="laboratoryInstrument">
                    <el-input v-model="data.laboratoryInstrument" maxlength='30'></el-input>
                </el-form-item>
            </div>
            <el-form-item label="介绍" prop="introduce">
                <el-input v-model="data.introduce" maxlength='500' type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="请输入内容" resize='none'></el-input>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="上传附件" prop="moduleList">
                    <uploadMutImg :moduleList='moduleList'  @changeImgList='changeImgList'></uploadMutImg>
                </el-form-item>
            </div>
            <integral v-bind:checked = 'checked' v-on:changeSelectIndex='changeSelectIndex($event)' v-bind:selectIndex = 'selectIndex' v-on:changeRules='changeRules($event)'></integral>
            <div class='submit'>
              <el-button @click='onSaveInfo("pestDataShare")'>保存</el-button>
              <el-button class='submitData' @click='onSubmitInfo("pestDataShare")'>提交</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
import Vue from 'vue';
import { Radio, RadioGroup, Upload, Loading, Message } from 'element-ui';
import uploadImg from '@/component/uploadImg/index.vue';
import integral from './integral';
import uploadMutImg from '@/component/uploadMutImg/index.vue';
import villageAddressSelect from '@/component/villageAddressSelect/index.vue';
import addressSelect from '@/component/addressSelect/index.vue';
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Upload);
Vue.use(Loading);
  export default {
    components: {
        uploadImg,
        integral,
        uploadMutImg,
        villageAddressSelect,
        addressSelect
    },
    data() {
        const validateShowImg = (rule, value, callback) => {
            if (!this.data.imageUrl) {
                callback(new Error('请上传头像'));
            }
            callback();
        };
        const validatePhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入联系电话'));
            } else {
                const reg = /^1[3456789]\d{9}$/;
                if (!reg.test(this.data.contactNumber)) {
                    callback(new Error('请输入正确的手机号码'));
                }
                callback();
            }
        };
        return {
            rules: {
                imageUrl: [
                     { required: true, validator: validateShowImg  }
                ],
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur'  },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                laboratoryAddress: [
                    { required: true, message: '请输入详细地址', trigger: 'blur'  }
                ],
                serviceScope: [
                    { required: true, message: '请输入服务范围', trigger: 'blur' }
                ],
                contactNumber: [
                    { required: true, validator: validatePhone, trigger: 'blur' }
                ],
                promoteItem: [
                    { required: true,  message: '请输入实验室仪器', trigger: 'blur' },
                    { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                laboratoryInstrument: [
                     { required: true, message: '请输入可开展子项目', trigger: 'blur' },
                      { max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                introduce: [
                     { required: true, message: '请输入介绍', trigger: 'blur' }
                ]
            },
            data: {
                baseId: '', //  基地id
                name: '', //  标题
                laboratoryProvince: '', //  省
                laboratoryCity: '', //  市
                laboratoryArea: '', //  区
                laboratoryAddress: '', //  地址
                address: '', //  详细地址
                serviceScope: '', // 服务范围
                contactNumber: '', //  电话
                relyonCompany: '', //  依托单位
                websiteUrl: '', //  官网
                imageUrl: '', //  图片
                introduce: '', //  简介
                imgPaths: '',
                cityType: '1',
                regionId: '',
                promoteItem: '', // 实验室仪器
                laboratoryInstrument: '' // 可开展子项目
            },
            param: {
                content: '',
                integral: 0,
                status: 0,
                type: 4,
                isPublic: 0
            },
            checked: true,
            selectIndex: -1,
            equipmentTypeList: [],
            timeOut: new Date(),
            moduleList: {
                module: 'dataShare',
                subModule: 'instrumentShare'
            },
            imgList: [],
            base_columns: [],
            baseInfo: {
                cityId: '',
                districtId: '',
                villageId: ''
            },
            detailAddress: '',
            labAddress: { 
                disabled: false, // 只为了显示不能选择地址
                provinceId: '',
                cityId: '',
                districtId: ''
            },
            cityDistrictVillage: {
                disabled: false,
                provinceId: '',
                cityId: "",
                districtId: "",
                villagesId: ''
            }
        };
    },
    async mounted() {
    },
    methods: {
        changeImg(file){
            this.data.imageUrl = file.filePath;
        },
        // 修改查看规则
        changeRules(data) {
            this.param.integral = data.integral;
            this.param.isPublic = data.isPublic;
            if (data.isPublic === 0) {
            this.checked = false;
            } else {
            this.checked = true;
            }
        },
        changeSelectIndex(data) {
            this.selectIndex = Number(data);
        },
        // 保存
        onSaveInfo(formName) {
            this.operation(formName, 0);
        },
        // 提交
        onSubmitInfo(formName) {
            this.operation(formName, 1);
        },
        operation(formName, idx) {
            if (new Date() - this.timeOut < 1000) {
                return true;
            }
            this.timeOut = new Date();
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.param.isPublic === 0 && this.param.integral === 0) {
                        Message('请设置规则积分');
                        return false;
                    }
                    this.param.status = idx;
                    this.addRecord();
                } else {
                    window.scroll(0, 400);
                    return false;
                }
            });
        },
        // 保存和提交记录
        async addRecord() {
            const loadingInstance = Loading.service({
                fullscreen: true,
                background: 'rgba(0,0,0,.4)'
            });
            const obj = this.param;
            this.data.imgPaths = this.pictureCollation();
            this.data.address = this.detailAddress + this.data.laboratoryAddress;
            obj.content = JSON.stringify(this.data);
            let api = '';
            if (this.$route.query && this.$route.query.update) {
                api = this.$fetchApi.updateRecord.api;
            } else {
                api = this.$fetchApi.addLaboratoryRecord.api;
            }
            const data = await this.$fetchData.fetchPost(obj, api, 'json');
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close();
            });
            if (data.code === '200') {
                if (data.result === 1) {
                    this.isSubmit = false;
                    Message('提交成功，请至app查看');
                }
            }
        },
        // 附件图片的整理
        pictureCollation() {
            let imgPaths = '';
            if (this.imgList.length > 0) {
                this.imgList.forEach((item, index) => {
                imgPaths += item;
                if (index < this.imgList.length - 1) {
                    imgPaths += ',';
                }
                });
            }
            return imgPaths;
        },
        changeImgList(fileList) {
            const files = [];
            if (fileList.length > 0) {
               fileList.forEach(item => {
                   files.push(item.filePath);
               });
            }
            this.imgList = files;
        },
        /** 返回三级地址信息 */
        addressInfo(val) {
            this.data.laboratoryProvince = val[0].id; // 居住地址 省id
            this.data.laboratoryCity = val[1].id;         // 居住地址 市id
            this.data.laboratoryArea = val[2].id; // 居住地址 区id
            this.data.regionId = val[2].id;
            this.detailAddress = val[0].name + val[1].name + val[2].name;
        },
        /** 返回市区村信息 */
        villageInfo(val) {
            const params = {
                pageNum: 1,
                pageSize: 1000,
                provinceId: '',
                cityId: '',
                districtId: '',
                villageId: ''
            };
            this.baseInfo.provinceId = val[0].id ? val[0].id : '';
            this.baseInfo.cityId = val[1].id ? val[1].id : '';
            this.baseInfo.districtId = val[2].id ? val[2].id : '';
            this.baseInfo.villageId = val[3].id ? val[3].id : '';
            if (!val[0]) {
                params.provinceId = val[0].id;
            } else if (!val[1]) {
                params.cityId = val[1].id;
            } else if (!val[2]) {
                params.districtId = val[2].id;
            } else {
                params.villageId = val[3].id;
            }
            this.data.baseId = '';
            this.getBaseList(params);
        },
        /** 获取基地列表 */
        async getBaseList(params) {
            const api = this.$fetchApi.baseScreeningContent.api;
            const data = await this.$fetchData.fetchPost(params, api, 'json');

            if (data.code === '200') {
                const obj = [{
                    baseId: '',
                    baseName: '暂无数据'
                }];
                this.base_columns = data.result ? data.result.rows : obj;
            } else {
                Message({
                    message: data.message,
                    type: 'error'
                });
            }
        }
    }
  };
</script>
<style lang="less" scoped>
    @import './common.less';
</style>