<template>
    <div>
        <div class='tab'>
            <nf-breadcrumb :breadList='breadList'></nf-breadcrumb>
            <div class='tabContent'>
                <el-tabs v-model="activeName">
                    <el-tab-pane label="南繁基地共享" name="0">
                        <baseDataShare></baseDataShare>
                    </el-tab-pane>
                    <el-tab-pane label="病虫害数据" name="1">
                        <pestDataShare v-if='activeName==1'></pestDataShare>
                    </el-tab-pane>
                    <el-tab-pane label="专家人才数据" name="2">
                        <expertData  v-if='activeName==2'></expertData>
                    </el-tab-pane>
                    <el-tab-pane label="实验室数据" name="3">
                        <labDataShare  v-if='activeName==3'></labDataShare>
                    </el-tab-pane>
                    <el-tab-pane label="仪器数据" name="4">
                        <instrumentShare  v-if='activeName==4'></instrumentShare>
                    </el-tab-pane>
                    <el-tab-pane label="第三方服务数据" name="5">
                        <serviceShare  v-if='activeName==5'></serviceShare>
                    </el-tab-pane>
                </el-tabs>
                <div class='myDataShare' @click='onDownLoadApp'>我的共享发布</div>
            </div>
            
            
            
        </div>
    </div>
</template>
<script>
import baseDataShare from './component/baseDataShare.vue';
import expertData from './component/expertData.vue';
import instrumentShare from './component/instrumentShare.vue';
import labDataShare from './component/labDataShare.vue';
import pestDataShare from './component/pestDataShare.vue';
import serviceShare from './component/serviceShare.vue';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';

export default {
    name: 'publishData',
    components: {
        baseDataShare,
        expertData,
        instrumentShare,
        labDataShare,
        pestDataShare,
        serviceShare,
        nfBreadcrumb
    },
    data() {
        return {
             activeName: 0,
             breadList: [{
                name: '资源共享'
            }, {
                name: '共享发布'
            }]
        };
    },
    mounted() {
        this.$store.commit("addCache", 'publishData');
        console.log(this.$route.query);
        if (this.$route.query && this.$route.query.type) {
            this.activeName = this.$route.query.type;
        }
    },
    methods: {
        onDownLoadApp() {
            this.$router.push({
                path: '/downLoad'
            });
        }
    }
};
</script>
<style lang="less" scoped>
    .tab{
        /deep/ .el-tabs__nav-scroll{
            width:1200px;
            margin:0 auto;
        }
    }
    /deep/ .el-tabs__item{
        width: 130px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        padding:0;
    }
    /deep/ .el-tabs__nav{
        padding-bottom: 10px;
    }
    .tabContent{
        position: relative;
        width:1180px;
        margin: 0 auto;
    }
     .myDataShare{
        width: 128px;
        height: 36px;
        background-color: #265ae1;
        border-radius: 2px;
        color: #fff;
        line-height: 36px;
        text-align: center;
        font-size: 16px;
        padding:0;
        outline: none;
        border: none;
        margin-bottom:18px;
        position: absolute;
        right:0;
        cursor: pointer;
        top:5px;
    }
</style>